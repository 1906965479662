// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-code-js": () => import("./../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-speaking-js": () => import("./../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-pages-technical-writing-js": () => import("./../src/pages/technical-writing.js" /* webpackChunkName: "component---src-pages-technical-writing-js" */),
  "component---src-pages-transcripts-js": () => import("./../src/pages/transcripts.js" /* webpackChunkName: "component---src-pages-transcripts-js" */),
  "component---src-pages-words-js": () => import("./../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */),
  "component---src-templates-article-jsx": () => import("./../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-engagement-jsx": () => import("./../src/templates/engagement.jsx" /* webpackChunkName: "component---src-templates-engagement-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-resources-jsx": () => import("./../src/templates/resources.jsx" /* webpackChunkName: "component---src-templates-resources-jsx" */),
  "component---src-templates-talk-jsx": () => import("./../src/templates/talk.jsx" /* webpackChunkName: "component---src-templates-talk-jsx" */),
  "component---src-templates-transcript-jsx": () => import("./../src/templates/transcript.jsx" /* webpackChunkName: "component---src-templates-transcript-jsx" */),
  "component---src-templates-workshop-jsx": () => import("./../src/templates/workshop.jsx" /* webpackChunkName: "component---src-templates-workshop-jsx" */)
}

